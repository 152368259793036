import { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFacebookPages } from './reducers/connectionsSlice'
import { fetchSocials, fetchUser, fetchTokens } from './reducers/userSlice'
import ActionConfirmation from './components/ActionConfirmation'
import FacebookPageError from './components/FacebookPageError'
import ConfirmModal from './components/ConfirmModal'

function FacebookPageChooser() {
  const dispatch = useDispatch()
  const pages = useSelector((state) => state.connections.instagram.allPages)
  const graphRequestDone = useSelector((state) => state.connections.instagram.graphRequestDone)

  const [showModal, setShowModal] = useState(false)
  const [pendingPageAction, setPendingPageAction] = useState(null)

  const [connectionMade, setConnectionMade] = useState(false)

  const tokens = useSelector((state) => state.user.tokens)

  useEffect(() => {
    dispatch(fetchSocials())
  }, [])

  const instagramPagesLoaded = useSelector((state) => state.connections.instagram.graphRequestDone)

  const connectedPages = useSelector((state) => state.connections.instagram.connectedPages)

  const connectedPageIds = connectedPages.map((page) => page.platform_account_id)

  const socials = useSelector((state) => state.user.socials)

  const pending_social_id = localStorage.getItem('pending_social_id')

  const pendingSocial = pending_social_id
    ? socials.find((social) => social.id === pending_social_id)
    : null

  useEffect(() => {
    dispatch(
      fetchFacebookPages({
        social_account_id: pending_social_id,
      }),
    )
    dispatch(fetchUser())
    dispatch(fetchTokens())
  }, [dispatch])

  const checkTokensForSocial = (tokens, pendingSocial) => {
    if (!tokens || !pendingSocial) return false

    return tokens.some((token) => {
      return token.social_account_id === pendingSocial.id && token.platform === 'instagram'
    })
  }

  const pendingSocialHasToken = checkTokensForSocial(tokens, pendingSocial)

  const pageOptions = pages.map((page) => {
    return {
      name: page.name,
      account_id: page.instagram_business_account?.id || page.id,
      access_token: page.access_token,
      has_instagram_business: !!page.instagram_business_account,
    }
  })

  const handlePageRegistration = async ({ id, name, access_token }) => {
    await axios.post('/api/instagram/register-page', {
      instagram_business_account_id: id,
      page_name: name,
      page_access_token: access_token,
      social_account_id: pending_social_id,
    })

    dispatch(fetchTokens())
  }

  const handlePageSelection = (page) => {
    if (page.has_instagram_business) {
      handlePageRegistration({
        id: page.account_id,
        name: page.name,
        access_token: page.access_token,
      })
    } else {
      setShowModal(true)
      setPendingPageAction({
        id: page.account_id,
        name: page.name,
        access_token: page.access_token,
      })
    }
  }

  const handleModalConfirm = () => {
    if (pendingPageAction) {
      handlePageRegistration(pendingPageAction)
    }
    setShowModal(false)
    setPendingPageAction(null)
  }

  const handleModalClose = () => {
    setShowModal(false)
    setPendingPageAction(null)
  }

  if (!instagramPagesLoaded) {
    return null
  }

  return (
    <div className="container mt-5">
      <ConfirmModal
        show={showModal}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        message="This page doesn't seem to have an Instagram connection. Click to continue if you are sure it is the correct page."
      />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              {pages.length > 0 ? (
                <>
                  {pendingSocialHasToken ? (
                    <ActionConfirmation />
                  ) : (
                    <>
                      <p
                        style={{
                          fontSize: '1.5rem',
                        }}
                        className="card-title text-center mb-4"
                      >
                        Confirm the page associated with your Instagram account
                      </p>
                      {pendingSocial && (
                        <p className="fw-bold text-center">
                          {pendingSocial?.platform_account_name}
                        </p>
                      )}
                      <p>
                        We use this connection to display media from your Instagram account on your
                        Talent Pitch Pro profile, along with account insights related to your
                        audience demographics and engagement.
                      </p>
                      <hr />
                      <ul className="list-group">
                        {pageOptions.map((page, i) => (
                          <li key={`page-${i}`} className="list-group-item border-0 my-2">
                            {connectedPageIds.includes(page.account_id) ? (
                              <button
                                className="btn btn-dark btn-lg w-100 d-flex align-items-center justify-content-center"
                                disabled
                              >
                                <span className="ms-2">{page.name} (Connected)</span>
                              </button>
                            ) : (
                              <button
                                className="btn btn-outline-primary btn-lg w-100 d-flex align-items-center justify-content-center"
                                onClick={() => {
                                  handlePageSelection(page)
                                }}
                              >
                                <span className="ms-2">{page.name}</span>
                              </button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              ) : (
                <>{graphRequestDone && <FacebookPageError />}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacebookPageChooser
