import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchSocials } from './reducers/userSlice'
import PlatformConnect from './PlatformConnect'

function ConnectController() {
  const dispatch = useDispatch()
  const params = useParams()

  const haveTokens = useSelector((state) => state.user.haveTokens)
  const user = useSelector((state) => state.user)

  useEffect(() => {
    dispatch(fetchSocials())
  }, [])

  if (!user.userFetched) {
    return 'Loading...'
  }

  return <PlatformConnect />
}

export default ConnectController
