import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchUser, loginUser, resetUser } from './reducers/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

function LoginPage() {
  const { loginCode } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  const userFetched = useSelector((state) => state.user.userFetched)
  const loginError = useSelector((state) => state.user.loginError)
  const hasLoggedIn = useSelector((state) => state.user.hasLoggedIn)

  useEffect(() => {
    if (userFetched && _.isEmpty(user.influencer_id) && _.isEmpty(loginCode)) {
      navigate('/contact-us')
    }
  }, [userFetched, user.influencer_id])

  useEffect(() => {
    const login = async () => {
      dispatch(loginUser(loginCode)).then(() => {
        dispatch(fetchUser())
      })
    }

    dispatch(resetUser())

    if (loginCode) {
      login()
    }
  }, [loginCode])

  useEffect(() => {
    // if user authed, navigate to home page
    if (user.authed && hasLoggedIn) {
      // do this after a delay
      setTimeout(() => {
        navigate('/')
      }, 600)
    }
  }, [user.authed, hasLoggedIn])

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              {user.authed ? (
                <div className="text-center">Welcome...</div>
              ) : (
                <div className="text-center">
                  {loginError ? (
                    <div className="alert alert-danger" role="alert">
                      {loginError}
                    </div>
                  ) : (
                    <>
                      <h2>Waiting for login...</h2>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
