import { Link } from 'react-router-dom'

function FacebookPageError() {
  return (
    <div className="">
      <h2 className="mb-3 text-center">There may be a problem</h2>
      <p>Thanks for connecting your Facebook account. We seem to have run into a problem.</p>
      <p>
        Because an Instagram "business" account must be connected to a Facebook page, we use the
        Facebook connection to confirm the page associated with your Instagram account. In this
        case, however, we can't find any pages associated with the Facebook account you connected.
        Please make sure you have a Facebook page connected to your Instagram account.
      </p>
      <p>
        If you connected the wrong account by accident, you can return to the main page, remove the
        connection you just added (by selecting the same Instagram account again), and retry.
      </p>
      <p>
        We will be looking into the issue to see if we can determine the cause of the problem. If
        you have any questions, please <a href="mailto:erick@talentpitchpro.com">contact us</a>.
      </p>
      <p className="text-center my-3">
        <Link to="/" className="btn btn-link">
          Main Page
        </Link>
      </p>
    </div>
  )
}

export default FacebookPageError
