import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom'
import axios from 'axios'

function YoutubeRedirect() {
  const location = useLocation()
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const pending_social_id = localStorage.getItem('pending_social_id')

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const errorParam = queryParams.get('error')
    const errorDescription = queryParams.get('error_description')

    const scope = queryParams.get('scope')

    const provideCode = async () => {
      if (errorParam) {
        console.log('error', errorParam, errorDescription)
        setError(errorDescription)
        return
      }

      try {
        const response = await axios.post(`/api/auth/youtube/callback`, {
          code: code,
          scope,
          social_account_id: pending_social_id,
        })

        // if the response has no error, we should redirect to the `/youtube/pages` route
        if (!response.data.error) {
          setSuccess(true)
          // localStorage.removeItem('pending_social_id')
          navigate('/youtube/channels')
        } else {
          console.log(response.data.error)
          setError(response.data.error)
        }
      } catch (err) {
        console.error('Error during YouTube OAuth callback:', err)
        setError('An error occurred during the YouTube OAuth process.')
      }
    }

    provideCode()
  }, [location.search])

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <div className="text-center">
                {!error ? (
                  <>
                    <h2 className="card-title text-center mb-4">Redirected, verifying response</h2>
                    <span className="visually-hidden">Loading...</span>
                    <div className="spinner-border text-primary" role="status"></div>
                  </>
                ) : (
                  <p className="">
                    Something went wrong while getting authorization. Please{' '}
                    <Link to="/connect/youtube">try again</Link> or contact us for assistance.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YoutubeRedirect
